// import { useEffect, useState } from 'react';

// const TawkToIntegration = () => {
//     const [scriptUrl, setScriptUrl] = useState(null);

//     useEffect(() => {
//         // Poll for the data in localStorage
//         const pollLocalStorage = () => {
//             // const frontendInfo = JSON.parse(localStorage.getItem("frontendInfo"));
//             // if (frontendInfo && frontendInfo.tawk_to_id) {
//             //     setScriptUrl(frontendInfo.tawk_to_id); // Set the URL if it's found in localStorage
//             // }

//             let tawk = localStorage.getItem("TAWK_TO_ID");
//             if (tawk) {
//                 setScriptUrl(tawk);
//             }

//             // setScriptUrl("https://embed.tawk.to/671a19902480f5b4f593243a/1iauv7ni4"); // Set the URL if it's found in localStorage
//         };

//         // Start polling every 2 seconds
//         const intervalId = setInterval(() => {
//             if (!scriptUrl) {
//                 pollLocalStorage();
//             } else {
//                 clearInterval(intervalId); // Stop polling once we have the URL
//             }
//         }, 2000); // Adjust polling interval as needed

//         // Cleanup interval on component unmount
//         return () => clearInterval(intervalId);
//     }, [scriptUrl]);

//     useEffect(() => {
//         if (scriptUrl) {
//             // Create and append the Tawk.to script when URL is available
//             const tawkScript = document.createElement("script");
//             tawkScript.src = scriptUrl;
//             tawkScript.async = true;
//             tawkScript.charset = "UTF-8";
//             tawkScript.setAttribute("crossorigin", "*");
//             document.head.appendChild(tawkScript);

//             // Cleanup script on component unmount
//             return () => {
//                 document.head.removeChild(tawkScript);
//             };
//         }
//     }, [scriptUrl]); // Re-run when scriptUrl changes

//     return null;
// };

// export default TawkToIntegration;




import { useEffect, useState } from 'react';

const SmartsuppIntegration = () => {
    const [smartsuppKey, setSmartsuppKey] = useState(null);

    useEffect(() => {
        // Poll for the Smartsupp key in localStorage
        const pollLocalStorage = () => {
            // const frontendInfo = JSON.parse(localStorage.getItem("frontendInfo"));
            // if (frontendInfo && frontendInfo.tawk_to_id) {
            //     setSmartsuppKey(frontendInfo.tawk_to_id); // Set the key if found
            // }

            let tawk = localStorage.getItem("TAWK_TO_ID");
            if (tawk) {
                setSmartsuppKey(tawk);
            }

        };

        // Start polling every 2 seconds
        const intervalId = setInterval(() => {
            if (!smartsuppKey) {
                pollLocalStorage();
            } else {
                clearInterval(intervalId); // Stop polling once we have the key
            }
        }, 2000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [smartsuppKey]);

    useEffect(() => {
        if (smartsuppKey) {
            // Create and append the Smartsupp script when key is available
            const smartsuppScript = document.createElement('script');
            smartsuppScript.type = 'text/javascript';
            smartsuppScript.async = true;
            smartsuppScript.charset = 'utf-8';
            smartsuppScript.src = 'https://www.smartsuppchat.com/loader.js?';
            document.head.appendChild(smartsuppScript);

            // Initialize Smartsupp with the dynamic key
            window._smartsupp = window._smartsupp || {};
            // window._smartsupp.key = "f79568c16c513b864c703f5dc73acfc1641dd397";
            window._smartsupp.key = smartsuppKey;

            // Cleanup script on component unmount
            return () => {
                document.head.removeChild(smartsuppScript);
                delete window._smartsupp;
                delete window.smartsupp;
            };
        }
    }, [smartsuppKey]);

    return null;
};

export default SmartsuppIntegration;

